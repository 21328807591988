import UserService from "../keycloak/UserService";
import TrustGridLogo from "../assets/images/verifierLogo.svg";

import LandingImage from "../assets/images/landing-img.svg";
import ItemLogo1 from "../assets/images/welcome-1.svg";
import ItemLogo2 from "../assets/images/welcome-2.svg";
import ItemLogo3 from "../assets/images/welcome-3.svg";
import "../assets/sass/common/_welcome.scss";
import { Col, Row } from "react-bootstrap";

const Welcome = () => (
  <div className="landing-page-main container-fluid p-0">
    <div className="body">
      <div className="brand-logo">
        <img
          src={TrustGridLogo}
          alt="logo"
          width={240}
          height={50}
          className="img-fluid"
        />
      </div>
      <Row className="mb-5">
        <Col
          xl={7}
          lg={7}
          md={12}
          sm={12}
          className="order-lg-1 order-md-2 order-sm-2 order-1"
        >
          <div className="welcome-content">
            <h4>
              Welcome to the TrustGrid<sup className="sup">TM</sup> Verifier
              Center
            </h4>
            <p className="mb-4 text-justify">
              TrustGrid’s innovative platform is designed to empower you to
              issue secure and verifiable credentials on the blockchain. Join us
              in leveraging the power of blockchain technology for verifying the
              authenticity of credentials presented by holders (individuals or
              entities) within the SSI ecosystem.
            </p>
            <button
              className="dsm-btn-primary w-50"
              onClick={() => UserService.doLogin()}
            >
              Login
            </button>
          </div>
        </Col>
        <Col
          xl={5}
          lg={5}
          md={12}
          sm={12}
          className="order-lg-2 order-md-1 order-sm-1 order-2"
        >
          <div className="decentral-logo">
            <img src={LandingImage} alt="logo" />
          </div>
        </Col>
      </Row>
    </div>
    <div className="footer">
      <div className="item">
        <div className="item-logo">
          <img src={ItemLogo1} alt="logo" className="img-fluid" />
        </div>
        <h4>Create Verifiable Claims</h4>
        <p>
          Select specific attributes from the available service and create
          verifiable claims that are secure and trustworthy.
        </p>
      </div>{" "}
      <div className="item">
        <div className="item-logo">
          <img src={ItemLogo2} alt="logo" className="img-fluid" />
        </div>
        <h4>Generate Secure QR Codes</h4>
        <p>
          Each claim is encoded into a QR code, ensuring its authenticity and
          integrity.
        </p>
      </div>{" "}
      <div className="item">
        <div className="item-logo">
          <img src={ItemLogo3} alt="logo" className="img-fluid" />
        </div>
        <h4>Facilitate Credential Sharing</h4>
        <p>
          Holders can easily share their verifiable credentials by scanning the
          QR code and selectively sharing the necessary information.
        </p>
      </div>
    </div>
  </div>
);

export default Welcome;
