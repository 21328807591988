import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Select from "react-select";
import { Col, Row, Spinner } from "react-bootstrap";
import { toastError, toastSuccess, toastWarning } from "../Common/ToastAlert";
import WarningIcon from "../../assets/images/warning-circle.svg";
import { useRef } from "react";

const ServiceModal = ({ show, onHide, data, setOpenModal, refetchData }) => {
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [isMainModalVisible, setIsMainModalVisible] = useState(show);
 


  const [inputList, setInputList] = useState(
    data.attributes.map((attr) => ({ ...attr, type: attr.type }))
  );




  const handleCancelPublish = () => {
    setConfirmModalShow(false);
    setIsMainModalVisible(true);
  };

  return (
    <>
      <Modal
        show={isMainModalVisible}
        onHide={onHide}
        size="md"
        className="dsm-modal-popup"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-capitalize">{data.name}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label fw-bold">Attributes</label>
          {inputList.map((attribute, idx) => {
            return (
              <Row className="mb-2" key={idx}>
                {data.published ? (
                  <Col lg={12}>
                    <div className="textfield-container ">
                      <input
                        className="dsm-inputbox text-capitalize"
                        name="name"
                        placeholder={attribute}
                        value={Object.values(attribute).join("")}
                        disabled
                      />
                    </div>
                  </Col>
                ) : (
                  <Col lg={8}>
                    <div className="textfield-container ">
                      <input
                        className="dsm-inputbox w-100 text-capitalize"
                        name="name"
                        placeholder="Attribute name"
                        value={attribute.name}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            );
          })}
        </Modal.Body>
      </Modal>

     
    </>
  );
};

export default ServiceModal;
