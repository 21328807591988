import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

// USER API
export async function inviteUser(data) {
  return await axios.post(`${BASE_URL}/users/inviteUser`, data);
}

export async function getUserByEmail(data) {
  return await axios.post(`${BASE_URL}/users/getUserByEmail`, data);
}

export async function getUser(verifierId) {
  return await axios.post(`${BASE_URL}/users/verifierDetails/`, {
    verifierId: verifierId,
  });
}

export async function updateUser(data) {
  return await axios.post(`${BASE_URL}/users/updateVerifier`, data);
}

// CLAIMS API
export async function getAllServices(networkId) {
  return await axios.post(`${BASE_URL}/claim/getServicesByNetworkId`, {
    networkId: networkId,
  });
}

export async function getAllClaims(verifierId) {
  return await axios.get(`${BASE_URL}/claim/getAllClaims/${verifierId}`);
}

export async function createClaims(data) {
  return await axios.post(`${BASE_URL}/claim/create`, data);
}

export async function publishClaims(data) {
  return await axios.post(`${BASE_URL}/claim/publish`, data);
}

export async function updateClaim(data) {
  return await axios.post(`${BASE_URL}/claim/update`, data);
}

export async function deleteClaim(id) {
  return await axios.delete(`${BASE_URL}/claim/${id}`);
}
export async function getLatestInvitation(data) {
  return await axios.post(`${BASE_URL}/wallet/getLatestInvitation`, data);
}

export async function requestedProofList(data) {
  return await axios.post(`${BASE_URL}/proof`, data);
}

export async function getPresentation(data) {
  return await axios.post(`${BASE_URL}/proof/getPresentation`, data);
}

// Network Api

export async function getNetworkDetails(networkId) {
  return await axios.post(`${BASE_URL}/network`, { networkId: networkId });
}

export async function getIssuersByNetworkId(networkId) {
  return await axios.post(`${BASE_URL}/issuers`, { networkId: networkId });
}

export async function getAllConnection() {
  return await axios.post(`${BASE_URL}/wallet/getAllConnection`, {});
}
