import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
// import { getCredentialById } from "../../api/users";
import { formatDate } from "../../util/inputvalidations";
import { useLocation } from "react-router-dom";

const MoreDetails = () => {
  const location = useLocation();
  const { id } = location.state || {};
  const [data, setData] = useState({});
  const [connectionData, setConnectionData] = useState({});
  const [tagData, setTagData] = useState({});
  const [metadata, setmetaData] = useState({});

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const showData = async () => {
      try {
        setLoading(true);
        const res = await id;
        setData(res);
        setConnectionData(res.connection);
        setTagData(res.connection._tags);
        setmetaData(res.metadata["_anoncreds/credential"]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    showData();
  }, []);

  return (
    <>
      <div className="right-container">
        <div className="main-header">
          <Header title="Connection Details" />
        </div>

        <div className="inner-container">
          <div className=" bigwidget">
            <div className="col-xs-12 col-lg-12 col-md-6 my-1">
              <div
                className={`card shadow text-dark   bg-opacity-25 borders-b`}
              >
                <div className="card-body">
                  <h3 className="card-subtitle mb-2 ">
                    <i
                      className={`bi bi-person-vcard text-light-blue  mx-2`}
                    ></i>
                    Connection Tags
                  </h3>
                  <hr className="" />
                  <div className="row ">
                    <div className="col-4">Label</div>
                    <div className="col-8">{connectionData?.theirLabel}</div>
                  </div>
                  <hr className="" />
                  <div className="row ">
                    <div className="col-4">State</div>
                    <div className="col-8 fw-bolder text-capitalize">
                      {tagData?.state}
                    </div>
                  </div>
                  <hr className="" />
                  <div className="row ">
                    <div className="col-4">Last Updated</div>
                    <div className="col-8">
                      {formatDate(connectionData?.updatedAt)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-lg-12 col-md-6 my-1">
              <div className={`card shadow text-dark  bg-opacity-25 borders-b`}>
                <div className="card-body">
                  <h3 className="card-subtitle mb-2 ">
                    <i className={`bi bi-bookmark text-light-blue  mx-2`}></i>
                    Blockchain Tags
                  </h3>
                  <hr className="" />
                  <div className="row">
                    <div className="col-4">Connection ID</div>
                    <div className="col-8 ">{data.connection_id}</div>
                  </div>
                  <hr className="" />
                  <div className="row ">
                    <div className="col-4">Out Of Band ID</div>
                    <div className="col-8">{connectionData?.outOfBandId}</div>
                  </div>
                  <hr className="" />
                  <div className="row ">
                    <div className="col-4">Their DID</div>
                    <div className="col-8">{connectionData?.theirDid}</div>
                  </div>
                  <hr className="" />
                  <div className="row ">
                    <div className="col-4">Thread ID</div>
                    <div className="col-8 ">{connectionData?.threadId}</div>
                  </div>
                  <hr className="" />
                  <div className="row ">
                    <div className="col-4">Protocol</div>
                    <div className="col-8 ">{connectionData?.protocol}</div>
                  </div>
                  <hr className="" />
                  <div className="row ">
                    <div className="col-4">Role</div>
                    <div
                      className="col-8"
                      style={{ textTransform: "capitalize" }}
                    >
                      {connectionData?.role}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MoreDetails;
