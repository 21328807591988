import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import { Spinner, Table } from "react-bootstrap";
import { toast } from "react-toastify";

import { getAllConnection } from "../../api/verifier";

import EmptyScreen from "../Common/EmptyScreen";
import { formatDate } from "../../util/inputvalidations";
import NoResultImage from "../../assets/images/no-result.svg";
import CopyIcon from "../../assets/images/copy.svg";
import { toastError, toastSuccess, toastWarning } from "../Common/ToastAlert";
import Widget from "../Common/Widget";

const Connections = () => {
  const navigate = useNavigate();

  const [connectionData, setConnectionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [count, setCount] = useState({});
  const [filteredCount, setFilteredCount] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [filteredAndSortedProofs, setFilteredAndSortedProofs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const getConnection = await getAllConnection();
        const res = getConnection.data.result;
        setConnectionData(res);
        const sortedData = res.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        setFilteredData(sortedData);
        setFilteredAndSortedProofs(sortedData);
        setCounts(res);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSearch = (query) => {
    setSearchItem(query);
    const filtered = connectionData.filter((item) =>
      item.connection_id.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filtered);
    setFilteredCounts(filtered);
  };

  const handleClickOnId = async (id) => {
    navigate("/connections/details", { state: { id: id } });
  };

  const noReulstContent = {
    heading: "Sorry! No Results Found.",
    subHeading: [
      "We couldn’t find what you were looking for. Please try other ways.",
    ],
  };

  const handleCopy = (id) => {
    navigator.clipboard.writeText(id).then(
      (id) => {
        toast.success("DID copied successfully", toastSuccess());
      },
      (err) => {
        toast.error("Oops! Try Again Later." + err, toastError());
      }
    );
  };

  const setCounts = (allConnections) => {
    const data = {
      done: 0,
      undone: 0,
    };
    allConnections.map((x) => {
      if (x.state === "complete") data.done += 1;
      else data.undone += 1;
    });
    setCount(Object.assign({}, data));
  };

  const setFilteredCounts = (allConnections) => {
    const data = {
      done: 0,
      undone: 0,
    };
    allConnections.map((x) => {
      if (x.state === "complete") data.done += 1;
      else data.undone += 1;
    });
    setFilteredCount(Object.assign({}, data));
  };

  const formatCellData = (text) => {
    if (text.length <= 6) return text;
    return text.slice(0, 3) + "..." + text.slice(-3);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Check the screen size on initial render
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const totalConnectionData = () => {
    setFilteredAndSortedProofs(filteredData);
  };

  const completeConnectionData = () => {
    const filteredCompleteConnection = filteredData.filter(
      (item) => item.state === "complete"
    );
    setFilteredAndSortedProofs(filteredCompleteConnection);
  };

  const pendingConnectionData = () => {
    const filteredPendingConnection = filteredData.filter(
      (item) => item.state === "N/A"
    );
    setFilteredAndSortedProofs(filteredPendingConnection);
  };

  return (
    <div className="right-container">
      <div className="main-header">
        <Header title="Holder Connections" />
      </div>

      <div className="inner-container">
        <div className="claims-card-section widget">
          <Widget
            filter={totalConnectionData}
            borderColor="b"
            icon="circle-fill"
            color="blue"
            title="Total Connections"
            value={
              filteredData?.length === 0
                ? connectionData.length
                  ? connectionData.length
                  : "-"
                : filteredData.length
                ? filteredData.length
                : "-"
            }
          />

          <Widget
            filter={completeConnectionData}
            borderColor="g"
            icon="circle-fill"
            color="green"
            title="Completed Connections"
            value={
              filteredData?.length === connectionData.length
                ? count.done
                  ? count.done
                  : "0"
                : filteredCount.done
                ? filteredCount.done
                : "0"
            }
          />

          <Widget
            filter={pendingConnectionData}
            borderColor="o"
            icon="circle-fill"
            color="orange"
            title="Pending Connections"
            value={
              filteredData?.length === connectionData.length
                ? count.undone
                  ? count.undone
                  : "0"
                : filteredCount.undone
                ? filteredCount.undone
                : "0"
            }
          />
        </div>

        <div className="inner-body-container">
          <div className="header flex-row">
            <div className="search-bar-box search-box-wiz">
              <input
                type="search"
                className="search-bar"
                placeholder="Search"
                id="search-text-box"
                value={searchItem}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="body">
            <Table borderless className="requests-table submit-proof-table">
              <thead>
                <tr>
                  <th>Connection ID</th>
                  <th>State </th>
                  <th>Date </th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr className="empty-screen-tr">
                    <td colSpan={4} rowSpan={8}>
                      <div className="mt-4 d-flex align-items-center justify-content-center">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {filteredData?.length === 0 ? (
                      <tr className="empty-screen-tr">
                        <td colSpan={4}>
                          <EmptyScreen
                            content={noReulstContent}
                            emptyScreenImage={NoResultImage}
                          />
                        </td>
                      </tr>
                    ) : (
                      filteredAndSortedProofs?.map((connectionData) => {
                        return (
                          <tr>
                            <td>
                              {connectionData?.connection_id ? (
                                <>
                                  <img
                                    className="img-fluid copy-icon"
                                    src={CopyIcon}
                                    alt="copy"
                                    onClick={() =>
                                      handleCopy(connectionData?.connection_id)
                                    }
                                  />
                                  <span className="connection-id">
                                    {isMobile
                                      ? formatCellData(
                                          connectionData?.connection_id
                                        )
                                      : connectionData?.connection_id}
                                  </span>
                                </>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              <span style={{ textTransform: "capitalize" }}>
                                {connectionData ? connectionData.state : "0"}
                              </span>
                            </td>
                            <td>
                              {formatDate(connectionData?.connection.updatedAt)}
                            </td>
                            <td>
                              <span
                                className="info-badge"
                                onClick={() => handleClickOnId(connectionData)}
                              >
                                View
                              </span>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Connections;
