import React, { useState } from "react";
import Header from "../Header/Header";

import RequestedProofsModal from "./RequestedProofs";

const AddNewService = () => {
  return (
    <>
      <div className="right-container">
        <div className="main-header">
          <Header title="Create New Verifiable Claim" />
        </div>
        <div className="inner-container">
          <div className="inner-body-container">
            <div className="body d-flex flex-column justify-content-between">
              <RequestedProofsModal />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewService;
