import React, { useEffect, useState } from "react";
import { DropdownItem, DropdownMenu } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";

import LogoutIcon from "../../assets/images/logout.svg";
import UserIcon from "../../assets/images/logout-user.svg";
import UserService from "../../keycloak/UserService";
import LogoutPopup from "./LogoutPopup";

const LogoutDropdown = () => {
  const [user, setUser] = useState("");
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    // const storedUsername = localStorage.getItem("username");
    // if (storedUsername) {
    //   setUser(storedUsername);
    // } else {
    const fetchUsername = async () => {
      const username = UserService.getUsername()?.given_name;
      if (username) {
        setUser(username);
        // localStorage.setItem("username", username);
      }
    };

    fetchUsername();
    // }
  }, []);
  return (
    <React.Fragment>
      {openModal && (
        <LogoutPopup show={openModal} handleClose={() => setOpenModal(false)} />
      )}
      <div className="logout-main welcome-user">
        <p className="user-name">{user ? ` ${user}` : "Hello User"}</p>
        <Dropdown>
          <Dropdown.Toggle
            variant="success"
            className="dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img src={UserIcon} alt="user-icon" />
          </Dropdown.Toggle>
          <DropdownMenu
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
          >
            <DropdownItem>
              <button
                className="dropdown-item"
                onClick={() => setOpenModal(true)}
              >
                <img src={LogoutIcon} alt="logout" />
                <span className="logout-text">Logout</span>
              </button>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </React.Fragment>
  );
};

export default LogoutDropdown;
