const Widget = ({ borderColor, icon, color, value, title, filter }) => {
  return (
    <div
      className={`card shadow text-dark  bg-opacity-25 borders-${borderColor}`}
      onClick={filter}
    >
      <div className="card-body">
        <h6 className="card-subtitle mb-2 text-center">
          <i className={`bi bi-${icon} text-${color} mx-2`}></i>
          {title}
        </h6>
        <hr />

        <h1
          className={`col text-${color} text-center mx-4 fw-bolder display-6`}
        >
          {value}
        </h1>
      </div>
    </div>
  );
};

export default Widget;
