import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import Header from "../Header/Header";
import TextField from "../Common/TextField";
import TextArea from "../Common/TextArea";
import InviteUser from "./AddUser";
import {
  getUser,
  getUserByEmail,
  getNetworkDetails,
  updateUser,
} from "../../api/verifier";
import UserService from "../../keycloak/UserService";

import { toastError, toastSuccess, toastWarning } from "../Common/ToastAlert";

const Profile = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [did, setDid] = useState("");
  const [keycloakData, setKeycloakData] = useState([]);
  const [networkDetails, setnetworkName] = useState([]);
  const [editable, setEditable] = useState(false);
  const [editButton, setEditButton] = useState(true);
  const [error, setError] = useState("");
  const [isCancelled, setIsCancelled] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      website: "",
      country: "",
      address: "",
    },
  });

  const errorMessage = {
    website: {
      required: "Website is required",
      invalid: "Enter a Website URL",
    },
    country: {
      required: "Country is required",
      invalid: "Enter a valid Country Name",
    },
    address: {
      invalid: "Enter a valid Address",
    },
  };

  useEffect(() => {
    const setLoggedIn = async () => {
      if (!localStorage.getItem("dataInitialized")) {
        const email = UserService.getUsername()?.email;
        try {
          const sendEmail = await getUserByEmail({ email: email });
          const result = sendEmail?.data?.result;
          setKeycloakData(result);

          localStorage.setItem("networkId", result?.networkId);
          localStorage.setItem("verifierId", result?.verifierId);
          localStorage.setItem("id", result?.id);
          localStorage.setItem("dataInitialized", "true");
        } catch (error) {
          toast.warning(error.response.data.message, toastWarning());
        }

        setIsInitialized(true);
      } else {
        setIsInitialized(true);
      }
    };

    const initialize = async () => {
      await setLoggedIn();
    };

    initialize();
  }, []);

  useEffect(() => {
    const verifierId = localStorage.getItem("verifierId");
    const logTheUser = async () => {
      if (!verifierId) return;

      try {
        const networkName = await getNetworkDetails(
          localStorage.getItem("networkId")
        );

        const getLoggedInUser = await getUser(verifierId);

        const userData = getLoggedInUser?.data?.result;
        setnetworkName(networkName?.data?.result);
        setLoggedInUser(userData);

        setValue("website", userData?.website || "");
        setValue("country", userData?.country || "");
        setValue("address", userData?.address || "");

        if (userData?.website && userData?.country && userData?.address) {
          setEditable(false);
          setEditButton(true);
        } else {
          setEditable(false);
        }
        return userData;
      } catch (error) {
        toast.warning(
          error.response?.data?.message || "Error fetching user data",
          toastWarning()
        );
      }
    };

    if (isInitialized && verifierId) {
      logTheUser().then(async (res) => {
        if (res && res.active == false) {
          const randomString = [...Array(22)]
            .map(() => Math.random().toString(36)[2])
            .join("");
          try {
            const data = {
              verifierId: verifierId,
              active: true,
              verifierDiD: `did:tgrid:dev:${randomString}`,
            };
            const onUpdate = await updateUser(data);

            if (onUpdate.status === 200) {
              toast.success("Profile updated successfully", toastSuccess());
              const newDid = onUpdate?.data?.result?.verifierDiD;
              setDid(newDid);
            } else {
              toast.error(onUpdate.message, toastError());
            }
          } catch (error) {
            toast.warning(
              error.response?.data?.message || "Error updating user",
              toastWarning()
            );
          }
        }
      });
    }
  }, [isInitialized, setValue]);

  const updateUserDetails = async (data) => {
    setLoading(true);
    try {
      const onUpdate = await updateUser({
        ...data,
        verifierId: localStorage.getItem("verifierId"),
        networkId: localStorage.getItem("networkId"),
        orgName: loggedInUser?.orgName,
        userName: loggedInUser?.userName,
      });

      setLoading(false);
      if (onUpdate.status === 200) {
        toast.success("Profile updated successfully", toastSuccess());
        setEditable(false);
        setEditButton(true);
      } else {
        toast.error(onUpdate.message, toastError());
      }
    } catch (error) {
      setLoading(false);
      setError(error.response?.data?.message || "Error updating profile");
      toast.warning(
        error.response?.data?.message || "Error updating profile",
        toastWarning()
      );
    }
  };

  const cancelChanges = () => {
    setIsCancelled(true);
    setEditable(false);
    setEditButton(true);
  };

  const tooltipContent = ` 
            The Verifier, typically an organization like a business or service
            provider, ensures the trust and authenticity of credentials
            presented by holders. 
            `;
  const tooltipContentTwo = ` 
                        They request pertinent credentials to verify
            qualifications or compliance with specific requirements. Utilizing
            decentralized identifiers (DIDs) and advanced cryptographic
            techniques, the Verifier validates the authenticity and integrity of
            the submitted credentials.
            `;
  const tooltipContentThree = ` 
                         This validation process confirms the trustworthiness of the
            information, enabling the Verifier to make data-driven decisions
            regarding the holder, such as granting access or authorizing
            transactions. This technical process is critical for ensuring secure
            and reliable interactions that depend on verified digital
            credentials.
            `;

  return (
    <>
      {openModal && (
        <InviteUser show={openModal} handleClose={() => setOpenModal(false)} />
      )}
      <div className="right-container">
        <div className="main-header">
          <Header
            title="Profile"
            infoTooltip={true}
            tooltipContent={tooltipContent}
            tooltipContentTwo={tooltipContentTwo}
            tooltipContentThree={tooltipContentThree}
            tooltipPlacement="right"
          />
        </div>
        <div className="inner-container">
          <div className="inner-body-container">
            <div className="header">
              <div className="custom-select d-flex align-items-center">
                <div className="d-flex align-content-center gap-2">
                  {editButton && (
                    <button
                      type="button"
                      className="dsm-btn-secondary edit-details-wiz"
                      onClick={() => {
                        setEditable(true);
                        setEditButton(false);
                      }}
                    >
                      Edit Details
                    </button>
                  )}
                  <button
                    type="button"
                    className="dsm-btn-primary add-users-wiz"
                    onClick={() => setOpenModal(true)}
                  >
                    Add User
                  </button>
                </div>
              </div>
            </div>
            <div className="body profile-seciton">
              <div className="view-fields">
                <Row>
                  <Col lg={4} md={12} xl={3}>
                    <TextField
                      name="networkName"
                      label="Network Name"
                      type="text"
                      maxLength={40}
                      resize="none"
                      value={networkDetails?.name || "-"}
                      isViewMode={true}
                    />
                  </Col>
                  <Col lg={4} md={12} xl={3}>
                    <TextField
                      name="organizationName"
                      label="Organization Name"
                      type="text"
                      maxLength={40}
                      resize="none"
                      value={loggedInUser?.orgName || "-"}
                      isViewMode={true}
                    />
                  </Col>
                  <Col lg={4} md={12} xl={3}>
                    <TextField
                      name="decentralizedIdentity"
                      label="Decentralized Identity"
                      type="text"
                      maxLength={40}
                      resize="none"
                      value={
                        loggedInUser?.verifierDiD || did || "did:tgrid:dev:"
                      }
                      isViewMode={true}
                    />
                  </Col>
                </Row>
              </div>

              <form onSubmit={handleSubmit(updateUserDetails)}>
                <Row className="mb-4">
                  <Col lg={4} md={12} xl={4}>
                    <TextField
                      name="primaryEmail"
                      label="Primary Email"
                      type="text"
                      maxLength={40}
                      resize="none"
                      value={loggedInUser?.userName || "-"}
                      disabled={true}
                    />
                  </Col>
                  <Col lg={4}>
                    <Controller
                      control={control}
                      name="website"
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          mandatory={true}
                          label="Website URL"
                          type="text"
                          placeholder="Enter Website URL"
                          value={value ?? ""}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={errors?.website?.message}
                          disabled={!editable}
                        />
                      )}
                      rules={{
                        required: errorMessage?.website?.required,
                        pattern: {
                          value:
                            /^([a-zA-Z0-9.-]+)\.(com|net|co|org)(\/[^\s]*)?$/,
                          message: errorMessage.website.invalid,
                        },
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={4}>
                    <Controller
                      control={control}
                      name="country"
                      render={({ field: { onBlur, value, onChange } }) => (
                        <TextField
                          mandatory={true}
                          label="Country"
                          type="text"
                          placeholder="Enter Country Name"
                          value={value ?? ""}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={errors?.country?.message}
                          disabled={!editable}
                        />
                      )}
                      rules={{
                        required: errorMessage.country.required,
                        pattern: {
                          value: /^[A-Za-z ]*$/,
                          message: errorMessage.country.invalid,
                        },
                      }}
                    />
                  </Col>
                  <Col lg={4}>
                    <Controller
                      control={control}
                      name="address"
                      render={({ field: { onBlur, value, onChange } }) => {
                        return (
                          <TextArea
                            label="Address"
                            type="text"
                            placeholder="Enter Address here"
                            maxLength={150}
                            onBlur={onBlur}
                            error={errors?.address?.message}
                            value={value ?? ""}
                            onChange={onChange}
                            disabled={!editable}
                          />
                        );
                      }}
                      rules={{
                        pattern: {
                          value: /^[A-Za-z0-9 ,\-]+$/,
                          message: errorMessage.address.invalid,
                        },
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={8} md={12} sm={12}>
                    <div className="d-flex justify-content-end gap-2">
                      {!editButton && (
                        <React.Fragment>
                          <button
                            type="button"
                            className="dsm-btn-secondary"
                            onClick={cancelChanges}
                            disabled={isDirty}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="dsm-btn-primary"
                            disabled={loading || !isDirty}
                          >
                            {loading ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                variant="light"
                              />
                            ) : (
                              "Save"
                            )}
                          </button>
                        </React.Fragment>
                      )}
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
