import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import UserService from "./keycloak/UserService";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap-icons/font/bootstrap-icons.css";

const renderApp = () => {
  ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
      <ToastContainer
        className="dsm__Toastify__toast-container"
        position="top-center"
        hideProgressBar
        newestOnTop
        closeOnClick={false}
        autoClose={3000}
        limit={1}
      />
      <App />
    </React.StrictMode>
  );
};

UserService.initKeycloak(renderApp);
