import Modal from "react-bootstrap/Modal";

import "./ViewModal.css"; //

const ViewModal = ({ show, onHide, data }) => {

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="xl"
        className="dsm-modal-view bigwidget"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter ">
            <i className={`bi bi-person-vcard text-light-blue  mx-2`}></i>{" "}
            Encrypted Proofs
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={`text-dark bg-opacity-25`}>
            {data.map((item, index) => (
              <div>
                {Object.entries(item).map(([key, value], idx) => (
                  <>
                    <div className="row">
                      <div
                        className="col-3 text-capitalize"
                        style={{ overflow: "auto" }}
                      >
                        {" "}
                        {key}:{" "}
                      </div>
                      <div
                        className="col-9 fw-bolder text-capitalize"
                        style={{ wordBreak: "break-all" }}
                      >
                        {value.toString()}
                      </div>
                    </div>
                    <hr />
                  </>
                ))}
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewModal;
