import routes from "./Router";
import { RouterProvider } from "react-router-dom";
import { VerifierProvider } from "./components/context/claimsContext";

import RenderOnAuthenticated from "./keycloak/RenderOnAuthenticated";
import RenderOnAnonymous from "./keycloak/RenderOnAnonymous";
import Welcome from "./pages/Welcome";
import { useEffect } from "react";
import { isMobileDevice } from "./util/inputvalidations";
import { toast } from "react-toastify";
import { toastError } from "./components/Common/ToastAlert";

function App() {
  // useEffect(() => {
  //   if (isMobileDevice()) {
  //     toast.error(
  //       "For a better experience, please open this site in a browser.",
  //       toastError
  //     );
  //   }
  // }, []);
  return (
    <>
      <RenderOnAnonymous>
        <Welcome />
      </RenderOnAnonymous>
      <RenderOnAuthenticated>
        <VerifierProvider>
          <RouterProvider router={routes} />
        </VerifierProvider>
      </RenderOnAuthenticated>
    </>
  );
}

export default App;
