import { createBrowserRouter, Navigate } from "react-router-dom";
import Root from "./root/Root";
import Verifier from "./components/IssuedCredentials/IssuedCredentials";
import Claims from "./components/Claims/Claims";
import Profile from "./components/Profile/Profile";
import Notfound from "./components/Common/NotFound";
import AddedClaims from "./components/Claims/AddedClaims";
import AddNewServicePopup from "./components/Claims/AddNewService";
import Connections from "./components/Connections/Connections";
import MoreDetails from "./components/Connections/MoreDetails";
import Services from "./components/Services";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Navigate to="/profile" replace />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/verifiable-claims",
        element: <Claims />,
      },
      {
        path: "/verifiable-claims/add",
        element: <AddNewServicePopup />,
      },
      {
        path: "/requested-proofs",
        element: <Verifier />,
      },
      {
        path: "/connections",
        element: <Connections />,
      },
      {
        path: "/connections/details",
        element: <MoreDetails />,
      },
      {
        path: "/services",
        element: <Services />,
      },
    ],
  },
  {
    path: "*",
    element: <Notfound />,
  },
]);
export default routes;
