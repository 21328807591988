import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Header from "../Header/Header";
import Widget from "../Common/Widget";

import PublishClaims from "./PublishClaims";
import QrModal from "./QrModal";
import { getAllClaims, getIssuersByNetworkId } from "../../api/verifier";
import EmptyScreen from "../Common/EmptyScreen";
import { toastWarning } from "../Common/ToastAlert";

import EmptyIllustratorIcon from "../../assets/images/empty-screen.svg";
import Files from "../../assets/images/files-duotone.svg";
import QrSmall from "../../assets/images/qrsmall.svg";

import "./styles.scss";

const Claims = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [openQrModal, setOpenQrModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [claims, setClaims] = useState([]);
  const [selectedClaims, setSelectedClaims] = useState(null);
  const [issuers, setIssuers] = useState([]);
  const [count, setCount] = useState({});
  const [filteredAndSortedClaims, setFilteredAndSortedClaims] = useState([]);

  // Empty Screen
  const emptyScreenContent = {
    heading: "No Claims added yet",
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const getClaims = await getAllClaims(localStorage.getItem("verifierId"));
      const res = getClaims.data.result;
      setCounts(res);
      const sortedData = res.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      setClaims(sortedData);
      setFilteredAndSortedClaims(sortedData);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
      setLoading(false);
    }
  };

  const fetchClaims = async () => {
    try {
      setLoading(true);
      const getIssuers = await getIssuersByNetworkId(
        localStorage.getItem("networkId")
      );
      const res = getIssuers.data.result;
      setIssuers(res);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchClaims();
  }, []);

  const handleOpenPublishModal = (claim) => {
    setSelectedClaims(claim);
    setOpenModal(true);
  };

  const handleOpenQrModal = (claim) => {
    setSelectedClaims(claim);
    setOpenQrModal(true);
  };

  const totalClaims = () => {
    setFilteredAndSortedClaims(claims);
  };

  const publishedClaims = () => {
    const filteredPublishedClaims = claims.filter(
      (item) => item.published === true
    );
    setFilteredAndSortedClaims(filteredPublishedClaims);
  };

  const unpublishedClaims = () => {
    const filteredUnpublishedClaims = claims.filter(
      (item) => item.published === false
    );
    setFilteredAndSortedClaims(filteredUnpublishedClaims);
  };

  const setCounts = (claims) => {
    const data = {
      unpublished: 0,
      published: 0,
    };
    claims.map((x) => {
      if (x.published == true) data.published += 1;
      else if (x.published == false) data.unpublished += 1;
    });
    setCount(Object.assign({}, data));
  };

  return (
    <>
      {openModal && (
        <PublishClaims
          show={openModal}
          onHide={() => setOpenModal(false)}
          data={selectedClaims}
          setOpenModal={setOpenModal}
          refetchData={fetchData}
        />
      )}
      {openQrModal && (
        <QrModal
          show={openQrModal}
          onHide={() => setOpenQrModal(false)}
          setOpenQrModal={setOpenQrModal}
          id={selectedClaims.id}
        />
      )}
      <div className="right-container">
        <div className="main-header">
          <Header title="Verifiable Claims" />
        </div>
        <div className="inner-container">
          <div className="inner-body-container verifiable-container">
            <div className="header d-flex align-items-center justify-content-between">
              <div className="custom-select d-flex align-items-center">
                <div className="select-box create-claims-wiz">
                  <button
                    type="button"
                    className="dsm-btn-primary "
                    onClick={() => navigate("/verifiable-claims/add")}
                  >
                    Create New Verifiable Claims
                  </button>
                </div>
              </div>
              <h3 className="sub-title">All Claims</h3>
            </div>

            <div className="body">
              <div className="p-2">
                <div className="claims-card-section widget">
                  <Widget
                    borderColor="b"
                    icon="circle-fill"
                    color="blue"
                    title="Total
                        Claims"
                    value={claims.length ? claims.length : "0"}
                    filter={totalClaims}
                  />

                  <Widget
                    borderColor="g"
                    icon="circle-fill"
                    color="green"
                    title="Published
                        Claims"
                    value={count.published ? count.published : "0"}
                    filter={publishedClaims}
                  />
                  <Widget
                    borderColor="o"
                    icon="circle-fill"
                    color="orange"
                    title="Unpublished
                        Claims"
                    value={count.unpublished ? count.unpublished : "0"}
                    filter={unpublishedClaims}
                  />
                </div>

                <hr className="mb-4" />

                <div
                  className={`all-claims-cards ${
                    claims.length === 0
                      ? "d-flex gap-3 flex-wrap  justify-content-center"
                      : ""
                  }`}
                >
                  {loading ? (
                    <div className="text-center mt-5">
                      <div className="w-100 p-4 d-flex align-items-center">
                        <Spinner
                          animation="border"
                          size="lg"
                          variant="primary"
                        />
                      </div>
                    </div>
                  ) : claims.length == 0 ? (
                    <div className="text-center">
                      <EmptyScreen
                        content={emptyScreenContent}
                        emptyScreenImage={EmptyIllustratorIcon}
                      />
                    </div>
                  ) : (
                    filteredAndSortedClaims.map((claim, i) => (
                      <div key={i} className="service-card all-claim-wiz">
                        <div
                          className={`${
                            claim.published && "d-flex  justify-content-between"
                          }`}
                          style={{ height: "30px" }}
                        >
                          {claim.published && (
                            <div
                              style={{
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                              }}
                              onClick={() => handleOpenQrModal(claim)}
                            >
                              <img
                                src={QrSmall}
                                alt="qr small"
                                style={{ height: "100%" }}
                              />
                            </div>
                          )}

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            {claim.published ? (
                              <span className="success-badge badge-sm">
                                Published
                              </span>
                            ) : (
                              <span className="pending-badge badge-sm">
                                Unpublished
                              </span>
                            )}
                          </div>
                        </div>

                        <div
                          className="card-body text-center fw-bold"
                          onClick={() => handleOpenPublishModal(claim)}
                        >
                          <div
                            style={{
                              top: "-50px",
                              left: "50%",
                            }}
                          >
                            <img src={Files} alt="center icon" />
                          </div>
                          <p className="text-capitalize ">{claim.vcName}</p>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Claims;
