import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { getLatestInvitation } from "../../api/verifier";
import copy from "../../assets/images/Download.svg";

const QrModal = ({ show, onHide, setOpenModal, id }) => {
  const [qrData, setQrData] = useState({ claimId: "", invitationURL: "" });

  useEffect(() => {
    const getQrData = async () => {
      try {
        // const onLoad = await getLatestInvitation({verifierId: localStorage.getItem('verifierId')});
        const onLoad = await getLatestInvitation({ verifierId: "1" });

        const invitationURL = onLoad?.data?.result?.url;
        setQrData({ claimId: id, invitationURL: invitationURL });
      } catch (error) {
        toast.error("Oops! Try Again Later.");
      }
    };

    getQrData();
  }, []);

  const handleDownload = () => {
    const canvas = document.getElementById("qrCode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrcode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        className="dsm-modal-popup"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Verifiable Claim QR Code
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center flex-column justify-content-center ">
            <div className="m-3">
              <QRCode id="qrCode" value={JSON.stringify(qrData)} size={250} />
            </div>

            <button
              type="button"
              className="dsm-btn-secondary"
              onClick={handleDownload}
            >
              <img src={copy} alt="copy" /> Download QR Code
            </button>
            <div className="m-4 text-justify">
              <p>
                The verifier can showcase this QR code on their website to
                promote the claims available within this Consortium.
              </p>
              <p>
              By scanning this QR code, the holder can provide consent and selectively disclose specific information as needed. <br />This enables controlled sharing of data and enhanced privacy.
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QrModal;
