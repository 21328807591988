import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spinner, Table } from "react-bootstrap";
import Header from "../Header/Header";
import ServiceModal from "./ServiceModal";
import { getAllServices, getIssuersByNetworkId } from "../../api/verifier";
import EmptyScreen from "../Common/EmptyScreen";
import { toastWarning } from "../Common/ToastAlert";
import { formatDate } from "../../util/inputvalidations";

import EmptyIllustratorIcon from "../../assets/images/empty-screen.svg";
import NoResultImage from "../../assets/images/no-result.svg";

const Services = () => {
  const [openModal, setOpenModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [issuers, setIssuers] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isVerifiedFilterActive, setIsVerifiedFilterActive] = useState(false);
  const [isUnverifiedFilterActive, setIsUnverifiedFilterActive] =
    useState(false);

  // Empty Screen
  const emptyScreenContent = {
    heading: "No Services added yet",
  };

  const noReulstContent = {
    heading: "Sorry! No Results Found.",
    subHeading: [
      "We couldn’t find what you were looking for. Please try other ways.",
    ],
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const getServices = await getAllServices(
        localStorage.getItem("networkId")
      );
      const res = getServices.data.result;
      setServices(res);
      setFilteredData(res);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
      setLoading(false);
    }
  };

  const fetchIssuers = async () => {
    try {
      setLoading(true);
      const getIssuers = await getIssuersByNetworkId(
        localStorage.getItem("networkId")
      );
      const res = getIssuers.data.result;
      setIssuers(res);
      setLoading(false);
    } catch (error) {
      setLoading(true);
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchIssuers();
  }, []);

  const handleOpenModal = (service) => {
    setSelectedService(service);
    setOpenModal(true);
  };

  const handleVerifiedFilter = () => {
    setIsVerifiedFilterActive(!isVerifiedFilterActive);
  };
  const handleUnverifiedFilter = () => {
    setIsUnverifiedFilterActive(!isUnverifiedFilterActive);
  };

  const sortedServices = services
    ?.filter((service) => service.published)
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  const handleSearch = (query) => {
    setSearchItem(query);
    const filtered = sortedServices.filter(
      (service) =>
        service.name && service.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const filterByIssuerId = (id) => {
    const filtered = services.filter((service) => service.issuerId === id);
    setFilteredData(filtered);
  };

  return (
    <>
      {openModal && (
        <ServiceModal
          show={openModal}
          onHide={() => setOpenModal(false)}
          data={selectedService}
          setOpenModal={setOpenModal}
        />
      )}

      <div className="right-container">
        <div className="main-header">
          <Header title="Services" />
        </div>
        <div className="inner-container">
          <div className="inner-body-container verifiable-container">
            <div className="header d-flex align-items-center justify-content-between">
              <div className="custom-select d-flex align-items-center"></div>
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="sub-title">Available Services</h3>
              </div>
            </div>

            <div className="body">
              <div className="inner-body-container mb-5 available-services-container">
                <div className="header flex-row justify-content-between">
                  <div className="search-bar-box ">
                    <input
                      type="search"
                      className="search-bar"
                      placeholder="Search Services"
                      id="search-text-box"
                      value={searchItem}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </div>
                  <div>
                    <button
                      className="dsm-btn-light rounded-circle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="bi bi-funnel-fill fw-bolder "></i>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-secondary">
                      <li>
                        <h6 class="dropdown-header">Filter by Issuers</h6>
                      </li>
                      <li>
                        <hr class="dropdown-divider" />
                      </li>
                      {issuers.map((issuer) => (
                        <li
                          onClick={() => filterByIssuerId(issuer.id)}
                          className="text-capitalize"
                        >
                          <a class="dropdown-item" href="#">
                            {issuer.orgName}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="body">
                  <Table
                    hover
                    responsive
                    borderless
                    className="requests-table services-table"
                  >
                    <thead>
                      <tr>
                        <th>
                          <span className="connection-did-wiz">
                            Service Name
                          </span>
                        </th>
                        <th>
                          <span className="proof-status-wiz">Version</span>
                        </th>

                        <th>
                          <span className="date-wiz">Date</span>
                        </th>
                        <th>
                          <span className="view-presentation-wiz">
                            Attributes
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr className="empty-screen-tr">
                          <td colSpan={5} rowSpan={8}>
                            <div className="mt-4 d-flex align-items-center justify-content-center">
                              <Spinner animation="border" variant="primary" />
                            </div>
                          </td>
                        </tr>
                      ) : sortedServices.length === 0 ? (
                        <tr className="empty-screen-tr">
                          <td colSpan={5}>
                            <EmptyScreen
                              content={emptyScreenContent}
                              emptyScreenImage={EmptyIllustratorIcon}
                            />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {sortedServices.length == 0 ? (
                            <tr className="empty-screen-tr">
                              <td colSpan={5}>
                                <EmptyScreen
                                  content={noReulstContent}
                                  emptyScreenImage={NoResultImage}
                                />
                              </td>
                            </tr>
                          ) : (
                            sortedServices.map((service) => {
                              return (
                                <tr>
                                  {/* <td>{verifier.connectionId}</td> */}
                                  <td>
                                    <>
                                      <span>{service?.name}</span>
                                    </>
                                  </td>

                                  <td>
                                    <span className="success-badge">
                                      {service.version}
                                    </span>
                                  </td>

                                  <td> {formatDate(service.updatedAt)}</td>
                                  <td>
                                    <span
                                      className="info-badge"
                                      onClick={() => handleOpenModal(service)}
                                    >
                                      View
                                    </span>
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
