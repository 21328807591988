import {createContext, useState} from 'react';


// step 1
export const VerifierContext = createContext();

// step 2
export const VerifierProvider = ({children}) => {
    const [published, setPublished] = useState(false);
    const [addedClaims, setAddedClaims] = useState([]);
    const [cred, setCred] = useState('');

    // const [stateOFClaims, setstateOFClaims] = useState([]);

    



    return (
        <VerifierContext.Provider value={{published, setPublished, addedClaims, setAddedClaims, cred, setCred}}>
        {children}
      </VerifierContext.Provider>
    )
}

