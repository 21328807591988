import React, { useEffect, useRef, useState } from "react";
import Joyride, { STATUS } from "react-joyride";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
// import
import TrustGridLogo from "../assets/images/verifierLogo.svg";
import GreterThen from "../assets/images/greterthen.svg";
import LessThen from "../assets/images/lessthen.svg";
import CollapseLogo from "../assets/images/collapse-trustgrid.svg";

import Icon from "../assets/images/why-iam.svg";
import "../assets/sass/app.scss";

const Root = () => {
  const location = useLocation();
  const [start, setStart] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        window.innerWidth <= 768 &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target)
      ) {
        setCollapsed(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  // Wizard
  const steps = [
    // sidebar
    {
      target: ".profile-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            Access your profile to update your details, add more information,
            and invite additional users to manage the dashboard.
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".claims-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            Verifiable Claim (or proof request) is a request made by a verifier
            to a holder for specific information (like age, education, or
            specific group membership) or credentials (like vIC, vBL, etc).
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".reqested-proofs-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            These are the actual verifiable proofs shared by the holder with the
            verifier. They validate the claims made by the holder.
          </p>
          <p>
            <b>Example:</b> If a verifier requests proof of age, the holder
            shares Proof-of-age from a verifiable credential (such as a driver’s
            license) as the requested proof.
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "right",
    },
    // profile
    {
      target: ".edit-details-wiz",
      content: "Click here to update your profile information.",
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: ".add-details-wiz",
      content: "To add further details to your profile, please click here",
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: ".add-users-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            Please establish a new user account within the verifier
            organization. This user will receive identical entitlements to
            yours.
          </p>
          <p>
            They will have the authorization to act on behalf of the verifier
            organization or represent your office. Think of it as delegated
            access or a proxy user representing your office.
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },

    // claims

    {
      target: ".all-claim-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            <b>Unpublished:</b> Select the card to access the claim attributes,
            make edits to attributes for unpublished claims, and proceed with
            publishing them.
          </p>
          <p>
            <b>Published:</b> Click on the card to view the claim attributes for
            claims that have already been published.
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: ".create-claims-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            Click here to create new verifiable claim for specific attributes
            from an existing service within the network.
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: ".add-claim-name-wiz",
      content: "Enter the new verifiable claim name you wish to create.",
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: ".select-service-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            Select the service from the drop-down for which you want to create
            the Verifiable claim.(Service: Created by Issuers for the
            credentials they've issued, these include attributes derived from
            the credentials, alongside their data types, for verification
            purposes.)
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: ".select-service-toggle-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            Click the Toggle button to add attributes in the Verifiable claim.
            These attributes correspond to the fields from the service generated
            against the issued credentials.
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    // requested proof
    {
      target: ".connection-did-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            Connection DID (also known as pairwise DID) is a unique identifier
            for a secure connection between two parties (like Verifier and
            Holder) used for private communication/interactions (such as proof
            requests, credential exchange, or messaging).
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: ".proof-status-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>
            <b>Verfied:</b> Proof received from the Holder.
          </p>
          <p>
            <b>Not Verfied:</b> Awaiting proof from the Holder.
          </p>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },

    {
      target: ".state-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>Click here to view the current status of proof-request.</p>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: ".date-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>Last modified timestamp</p>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: ".view-presentation-wiz",
      content: (
        <div style={{ textAlign: "left" }}>
          <p>Click here to view the encrypted proof shared by the Holder</p>
        </div>
      ),
      disableBeacon: true,
      placement: "bottom",
    },

    // logout
    {
      target: ".welcome-user",
      content: "Click here to access the logout option.",
      disableBeacon: true,
    },
  ];

  // Joyride Custom Style
  const joyrideStyles = {
    options: {
      zIndex: 10000,
      arrowColor: "#fff",
      backgroundColor: "#fff",
      primaryColor: "#4398CD",
      textColor: "#000",
      width: 400,
      height: 200,
    },
    tooltip: {
      fontSize: "16px",
    },
    buttonNext: {
      backgroundColor: "#4398CD",
      color: "#fff",
    },
    buttonBack: {
      color: "#000",
    },
    buttonSkip: {
      color: "#666",
    },
  };

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status) || action === "close") {
      // Reset the tour state
      setStart(false);
      document.body.style.overflow = "auto"; // Restore scroll
    } else {
      document.body.style.overflow = "hidden"; // Hide scroll
    }
  };
  // Define a mapping of routes to steps that should be excluded
  const routeExclusions = {
    "/profile": [".profile-wiz"],
    "/verifiable-claims": [".claims-wiz"],
    "/requested-proofs": [".reqested-proofs-wiz"],
  };

  // Get the current route exclusions, default to an empty array
  const exclusions = routeExclusions[location.pathname] || [];

  // Filter the steps based on the current route exclusions
  const filteredSteps = steps.filter(
    (step) => !exclusions.includes(step.target)
  );
  return (
    <Container fluid className="main-layout p-0">
      <Joyride
        steps={filteredSteps}
        run={start}
        continuous={true}
        scrollToFirstStep={true}
        showProgress={false}
        showSkipButton={true}
        styles={joyrideStyles}
        callback={handleJoyrideCallback}
        locale={{
          last: "Finish",
        }}
      />
      <nav
        ref={sidebarRef}
        className={`sidebar-menu ${collapsed ? "collapsed-menu" : ""}`}
      >
        <button onClick={toggleSidebar} className="toggle-button">
          {collapsed ? (
            <img src={GreterThen} alt="logo" />
          ) : (
            <img src={LessThen} alt="logo" />
          )}
        </button>
        <div className="d-flex flex-column flex-shrink-0">
          <Link to="/">
            {collapsed ? (
              <div className="collapse-brand-logo">
                <img
                  src={CollapseLogo}
                  alt="logo"
                  width={48}
                  height={48}
                  className="img-fluid"
                />
              </div>
            ) : (
              <div className="brand-logo">
                <img src={TrustGridLogo} alt="logo" className="img-fluid" />
              </div>
            )}
          </Link>
          <ul className="nav nav-pills flex-column mb-auto nav-menu">
            <li className="nav-item">
              <NavLink to="/profile" className="profile">
                {!collapsed ? <span className="profile-wiz">Profile</span> : ""}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/services" className="services">
                {!collapsed ? <span className="claims-wiz">Services</span> : ""}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/verifiable-claims" className="verifiable-claim">
                {!collapsed ? (
                  <span className="claims-wiz">Verifiable Claims</span>
                ) : (
                  ""
                )}
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/requested-proofs" className="credentials">
                {!collapsed ? (
                  <span className="reqested-proofs-wiz">Requested Proofs</span>
                ) : (
                  ""
                )}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/connections" className="holder-connection">
                {!collapsed ? (
                  <span className="reqested-proofs-wiz">
                    Holder Connections
                  </span>
                ) : (
                  ""
                )}
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <Outlet />

      <button className="wizard-btn" onClick={() => setStart(true)}>
        <img src={Icon} alt="icon" />
        Need Help?
      </button>
    </Container>
  );
};

export default Root;
