import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { getAllServices, createClaims } from "../../api/verifier";
import { toastWarning, toastSuccess, toastError } from "../Common/ToastAlert";
import { Col, Row, Spinner } from "react-bootstrap";
import { VerifierContext } from "../context/claimsContext";
import customStyles from "../../assets/sass/common/selectStyles";
import TextField from "../Common/TextField";

const RequestedProofsModal = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm({ mode: "all", reValidateMode: "onChange" });

  const errorMessage = {
    claimName: {
      required: "Claim Name is required",
      invalid: "Enter a valid  Claim Name",
      min: "Claim Name should contain minimum of 3 characters",
    },
    selectOption: {
      required: "Please select a Service",
    },
  };

  const navigate = useNavigate();
  const { setCred } = useContext(VerifierContext);
  const [attributes, setAttributes] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  const options =
    services
      ?.filter((service) => service.published)
      .map((service) => {
        return {
          value: service.name,
          label: service.name,
          service: service,
        };
      }) || [];

  const handleSwitchChange = (idx) => {
    setAttributes((currentAttributes) => {
      const updatedAttributes = currentAttributes.map((item, index) =>
        index === idx ? { ...item, selected: !item.selected } : item
      );
      return updatedAttributes;
    });
  };

  const handleServiceSelect = (selectedOption) => {
    const service = selectedOption.service;
    setSelectedService(service);

    setCred(service?.credDefId);
    setAttributes(
      service.attributes.map((attr) => ({ ...attr, selected: false }))
    );
    setSelectedOption(selectedOption);
    setError((prev) => ({ ...prev, service: "" }));
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const getServices = await getAllServices(
        localStorage.getItem("networkId")
      );
      const res = getServices.data.result;
      setServices(res);

      setLoading(false);
    } catch (error) {
      setLoading(true);
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const createNewClaim = async (data) => {
    const newClaimData = {
      verifierId: localStorage.getItem("verifierId"),
      networkId: localStorage.getItem("networkId"),
      vcName: data.claimName,
      version: selectedService.version,
      invitationURLId: 1,
      attributes: attributes,
    };

    try {
      setLoading(true);
      const onCreate = await createClaims(newClaimData);
      if (onCreate.status === 200) {
        setLoading(false);
        toast.success("Claim is created successfully!", toastSuccess());
        navigate("/verifiable-claims");
      } else {
        setLoading(false);
        toast.error("Failed to create a claim", toastError());
      }
    } catch (error) {
      setLoading(false);
      setError(error.message);
      toast.warning(error.message, toastWarning());
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(createNewClaim)}>
        <Row className="mb-4">
          <Col lg={4}>
            <Controller
              control={control}
              name="claimName"
              render={({ field: { onBlur, value, onChange } }) => {
                return (
                  <TextField
                    label="Verifiable Claim Name"
                    type="text"
                    placeholder="Enter Claim Name"
                    maxLength={50}
                    onBlur={onBlur}
                    error={errors?.claimName?.message}
                    value={value ?? ""}
                    onChange={onChange}
                  />
                );
              }}
              rules={{
                required: errorMessage.claimName.required,
                pattern: {
                  value: /^[A-Za-z0-9 ]+$/,
                  message: errorMessage.claimName.invalid,
                },
                minLength: {
                  value: 3,
                  message: errorMessage.claimName.min,
                },
              }}
            />
          </Col>
          <Col lg={4}>
            <div className="mb-3 w-100 textfield-container">
              <label htmlFor="selectService" className="inputbox-label">
                Select Service
              </label>
              <Controller
                name="selectOption"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={customStyles(!!errors.selectOption)}
                    closeMenuOnSelect={true}
                    options={options}
                    onChange={(option) => {
                      field.onChange(option);
                      handleServiceSelect(option);
                    }}
                    placeholder="Select Service"
                  />
                )}
                rules={{
                  required: errorMessage.selectOption.required,
                }}
              />
              {errors.selectOption && (
                <small style={{ color: "#da1414", fontSize: 12 }}>
                  {errors.selectOption.message}
                </small>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          {selectedService?.attributes?.map((item, idx) => (
            <Col lg={4} className="mb-3" key={idx}>
              <Controller
                control={control}
                name={`attributes[${idx}].selected`}
                render={({ field: { onChange, value, onBlur, ref } }) => (
                  <div className="form-check form-switch d-flex justify-content-between align-items-center box py-2 px-2 select-service-toggle-wiz">
                    <label className="inputbox-label">
                      {item ? item : item?.name}
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={`switch-${idx}`}
                      checked={value}
                      onChange={(e) => {
                        onChange(e);
                        handleSwitchChange(idx);
                      }}
                      onBlur={onBlur}
                      ref={ref}
                    />
                  </div>
                )}
              />
            </Col>
          ))}
        </Row>
        <Row>
          <Col lg={8} xl={8} md={12} sm={12}>
            <div className="d-flex gap-2 justify-content-end">
              <button
                type="button"
                className="dsm-btn-secondary"
                onClick={() => navigate("/verifiable-claims")}
                disabled={loading}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="dsm-btn-primary"
                disabled={loading}
              >
                {loading ? (
                  <Spinner animation="border" size="sm" variant="dark" />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};

export default RequestedProofsModal;
