import React from "react";
import "../../assets/sass/common/_emptyscreen.scss";
const EmptyScreen = ({ emptyScreenImage, content }) => {
  return (
    <div className="empty-screen-wraper">
      <img src={emptyScreenImage} alt="empty screen img" />
      <div className="content">
        <p className="heading">{content?.heading}</p>
        <p className="sub-heading">{content?.subHeading}</p>
      </div>
    </div>
  );
};

export default EmptyScreen;
