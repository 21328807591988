import React, { useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { deleteClaim, publishClaims, updateClaim } from "../../api/verifier";
import { toastError, toastSuccess, toastWarning } from "../Common/ToastAlert";
import WarningIcon from "../../assets/images/warning-circle.svg";
import { VerifierContext } from "../context/claimsContext";

const PublishClaims = ({ show, onHide, data, setOpenModal, refetchData }) => {
  const { cred } = useContext(VerifierContext);
  const [addedClaimsData, setAddedClaimsData] = useState(data);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [isMainModalVisible, setIsMainModalVisible] = useState(show);

  const { control, handleSubmit } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      attributes: addedClaimsData.attributes.map((attr) => attr.selected),
    },
  });

  const handleCheckboxChange = (attributeKey) => {
    setAddedClaimsData((prevData) => ({
      ...prevData,
      attributes: prevData.attributes.map((attr, idx) =>
        idx === attributeKey ? { ...attr, selected: !attr.selected } : attr
      ),
    }));
  };

  const updatedData = async () => {
    setLoading(true);
    const dataToUpdate = {
      id: addedClaimsData.id,
      vcName: addedClaimsData.name,
      version: addedClaimsData.version,
      attributes: addedClaimsData.attributes,
    };
    try {
      const onSave = await updateClaim(dataToUpdate);

      if (onSave.status === 200) {
        setLoading(false);
        toast.success("Claim has been updated", toastSuccess());
        setOpenModal(false);
        refetchData();
      } else {
        setLoading(false);
        toast.error("Failed to save as draft", toastError());
      }
    } catch (error) {
      setLoading(false);
      setError(error.message);
      toast.warning(error.message, toastWarning());
    }
  };

  const extractStringValues = (obj) => {
    return Object.entries(obj)
      .filter(([key, value]) => typeof value === "string")
      .map(([key, value]) => value)
      .join("");
  };

  const selectedAttributes = addedClaimsData.attributes
    .filter((attribute) => attribute.selected)
    .map((attribute) => extractStringValues(attribute));

  const handleSubmitClaim = async () => {
    if (selectedAttributes.length === 0) {
      toast.warning("No attributes selected", toastWarning());
      return;
    }

    const dataToPublish = {
      id: addedClaimsData.id,
      credDefId: cred,
      attributes: selectedAttributes,
    };

    try {
      setPublishLoading(true);
      const onPublish = await publishClaims(dataToPublish);
      if (onPublish.status === 200) {
        setPublishLoading(false);
        toast.success("Claims have been published", toastSuccess());
        refetchData();
        setOpenModal(false);
      } else {
        setPublishLoading(false);
        toast.error("Failed to publish", toastError());
      }
    } catch (error) {
      setPublishLoading(false);
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again.",
        toastWarning()
      );
    }
  };

  const handlePublishClick = () => {
    setIsMainModalVisible(false);
    setConfirmModalShow(true);
  };

  const handleConfirmPublish = () => {
    setConfirmModalShow(false);
    handleSubmitClaim();
  };

  const handleCancelPublish = () => {
    setConfirmModalShow(false);
    setIsMainModalVisible(true);
  };

  const dataString = Object.entries(addedClaimsData.attributes || {})
    .map(([key, value]) => {
      const strings = extractStringValues(value);
      return `Attribute ${key}: ${strings} (Selected: ${value.selected})`;
    })
    .join("\n");

  const deleteClaims = async (id) => {
    try {
      const onDelete = await deleteClaim(id);
      refetchData();
      toast.success("Claim deleted sucessfully", toastSuccess);

      setOpenModal(false);
    } catch (error) {
      toast.warning(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again. ",
        toastWarning
      );
    }
  };

  return (
    <>
      <Modal
        show={isMainModalVisible}
        onHide={onHide}
        size="lg"
        className="dsm-modal-popup"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="text-capitalize">{data.vcName}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {data.published
              ? selectedAttributes?.map((a, i) => (
                  <div className="col-sm-6 mb-3">
                    <div className="form-check form-switch d-flex justify-content-between align-items-center box p-2">
                      <label className="inputbox-label text-capitalize" key={i}>
                        {a}
                      </label>
                    </div>
                  </div>
                ))
              : addedClaimsData?.attributes?.map((item, idx) => (
                  <div className="col-sm-6 mb-3" key={idx}>
                    <Controller
                      name={`attributes[${idx}]`}
                      control={control}
                      render={({ field }) => (
                        <div className="form-check form-switch d-flex justify-content-between align-items-center box py-1 px-2">
                          <label className="inputbox-label text-capitalize mb-0">
                            {extractStringValues(item)}
                          </label>
                          <input
                            className="form-check-input mb-1"
                            type="checkbox"
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.checked);
                              handleCheckboxChange(idx, e.target.checked);
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>
                ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {!data.published && (
            <>
              <button
                type="button"
                className="dsm-btn-danger-light"
                onClick={() => deleteClaims(data.id)}
              >
                Delete
              </button>
              <button
                type="button"
                className="dsm-btn-secondary"
                onClick={handleSubmit(updatedData)}
                disabled={loading}
              >
                {loading ? (
                  <Spinner animation="border" size="sm" variant="dark" />
                ) : (
                  "Save As Draft"
                )}
              </button>

              <button
                type="button"
                className="dsm-btn-primary"
                onClick={handlePublishClick}
              >
                Publish
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        show={confirmModalShow}
        onHide={handleCancelPublish}
        size="md"
        centered
        className="dsm-modal-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img src={WarningIcon} alt="warning" /> Do you want to Publish?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Once published, there will be no opportunity for further
            modifications.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="dsm-btn-secondary"
            onClick={handleCancelPublish}
          >
            Do it Later
          </button>
          <button
            type="button"
            className="dsm-btn-danger"
            onClick={handleConfirmPublish}
            disabled={publishLoading}
          >
            {publishLoading ? (
              <Spinner animation="border" size="sm" variant="light" />
            ) : (
              "Confirm Publish"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PublishClaims;
