export const validateEmail = (email) => {
  return email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/);
};
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};

export const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};
