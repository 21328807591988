import React from "react";
import { Modal } from "react-bootstrap";
import InfoDanger from "../../assets/images/info-danger.svg";
import UserService from "../../keycloak/UserService";

const LogoutPopup = ({ show, handleClose }) => {
  const logoutTheUser = () => {
    UserService.doLogout();
    localStorage.clear();
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      className="dsm-modal-popup"
      size="Md"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          <img src={InfoDanger} alt="icon" /> Log Out?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to proceed?</p>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="dsm-btn-secondary"
          onClick={handleClose}
        >
          Cancel
        </button>

        <button
          type="button"
          className="dsm-btn-danger"
          onClick={logoutTheUser}
        >
          Logout
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutPopup;
