import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import { Spinner, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  getPresentation,
  requestedProofList,
  getAllConnection,
} from "../../api/verifier";
import EmptyScreen from "../Common/EmptyScreen";
import NoResultImage from "../../assets/images/no-result.svg";
import NoDataAdded from "../../assets/images/empty-screen.svg";
import CopyIcon from "../../assets/images/copy.svg";
import ViewModal from "./ViewModal";
import { formatDate } from "../../util/inputvalidations";
import { toastError, toastSuccess } from "../Common/ToastAlert";
import Widget from "../Common/Widget";

const IssuedCredentials = () => {
  const [verifierData, setVerifierData] = useState([]);
  const [presentationData, setPresentationData] = useState([]);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [count, setCount] = useState({});
  const [filteredAndSortedProofs, setFilteredAndSortedProofs] = useState([]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const fetchVerifierProofData = async () => {
      try {
        setLoading(true);
        const getCredential = await requestedProofList({
          verifierId: localStorage.getItem("verifierId"),
        });
        const res = getCredential.data.result;
        setVerifierData(res);
        setCounts(res);
        const sortedData = res.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        setFilteredData(sortedData);
        setFilteredAndSortedProofs(sortedData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    fetchVerifierProofData();
  }, []);

  const handleSearch = (query) => {
    setSearchItem(query);
    const filtered = verifierData.filter(
      (verifier) =>
        verifier.connectionId &&
        verifier.connectionId.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleView = async (id) => {
    setOpenViewModal(true);
    try {
      const onLoad = await getPresentation({ proofRecordId: id });

      const res = onLoad?.data?.result;
      setPresentationData(res);
    } catch (error) {
      toast.error("Oops! Try Again Later.");
    }
  };

  const noReulstContent = {
    heading: "Sorry! No Results Found.",
    subHeading: [
      "We couldn’t find what you were looking for. Please try other ways.",
    ],
  };

  const noHolderContent = {
    heading: "No Requested Proofs are available",
  };

  // copy DID
  const handleCopy = (id) => {
    navigator.clipboard.writeText(id).then(
      (id) => {
        toast.success("DID copied successfully", toastSuccess());
      },
      (err) => {
        toast.error("Oops! Try Again Later." + err, toastError());
      }
    );
  };

  const formatCellData = (text) => {
    if (text.length <= 6) return text;
    return text.slice(0, 3) + "..." + text.slice(-3);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const setCounts = (verifierData) => {
    const data = {
      pending: 0,
      verified: 0,
      reject: 0,
    };
    verifierData.map((x) => {
      if (x.state == "done") data.verified += 1;
      else if (x.state === "request-sent") data.pending += 1;
      else if (x.state === "abandoned") data.reject += 1;
    });
    setCount(Object.assign({}, data));
  };

  const allRequestedClaims = () => {
    setFilteredAndSortedProofs(filteredData);
  };

  const verifiedProofs = () => {
    const filteredVerifiedClaims = filteredData.filter(
      (item) => item.state === "done"
    );
    setFilteredAndSortedProofs(filteredVerifiedClaims);
  };

  const pendingClaims = () => {
    const filteredPendingClaims = filteredData.filter(
      (item) => item.state === "request-sent"
    );
    setFilteredAndSortedProofs(filteredPendingClaims);
  };
  const rejectedClaims = () => {
    const filteredRejectClaims = filteredData.filter(
      (item) => item.state === "abandoned"
    );
    setFilteredAndSortedProofs(filteredRejectClaims);
  };

  return (
    <>
      {openViewModal && (
        <ViewModal
          show={openViewModal}
          onHide={() => setOpenViewModal(false)}
          data={presentationData}
        />
      )}
      <div className="right-container">
        <div className="main-header">
          <Header title="Requested Proofs" />
        </div>
        <div className="inner-container">
          <div className="request-proof-card-section widget">
            <Widget
              borderColor="b"
              icon="circle-fill"
              color="blue"
              title="All Claims"
              value={
                filteredData?.length === 0
                  ? verifierData.length
                    ? verifierData.length
                    : "-"
                  : filteredData.length
                  ? filteredData.length
                  : "-"
              }
              filter={allRequestedClaims}
            />
            <Widget
              borderColor="g"
              icon="circle-fill"
              color="green"
              title="Verified Proofs"
              value={count.verified ? count.verified : "0"}
              filter={verifiedProofs}
            />
            <Widget
              borderColor="o"
              icon="circle-fill"
              color="orange"
              title="Pending Claims"
              value={count.pending ? count.pending : "0"}
              filter={pendingClaims}
            />{" "}
            <Widget
              borderColor="d"
              icon="circle-fill"
              color="dangers"
              title="Rejected Claims"
              value={count.reject ? count.reject : "0"}
              filter={rejectedClaims}
            />
          </div>

          <hr className="mb-4" />

          <div className="inner-body-container">
            <div className="header flex-row">
              <div className="search-bar-box">
                <input
                  type="search"
                  className="search-bar"
                  placeholder="Search Connection DID"
                  id="search-text-box"
                  value={searchItem}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="body">
              <Table borderless className="requests-table submit-proof-table">
                <thead>
                  <tr>
                    <th>
                      <span className="connection-did-wiz">Connection DID</span>
                    </th>
                    <th>
                      <span className="proof-status-wiz">Proof Status</span>
                    </th>
                    <th>
                      <span className="state-wiz">State</span>
                    </th>
                    <th>
                      <span className="date-wiz">Date</span>
                    </th>
                    <th>
                      <span className="view-presentation-wiz">
                        View Presentation
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr className="empty-screen-tr">
                      <td colSpan={5} rowSpan={8}>
                        <div className="mt-4 d-flex align-items-center justify-content-center">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      </td>
                    </tr>
                  ) : verifierData.length === 0 ? (
                    <tr className="empty-screen-tr">
                      <td colSpan={5}>
                        <EmptyScreen
                          content={noHolderContent}
                          emptyScreenImage={NoDataAdded}
                        />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {filteredData.length == 0 ? (
                        <tr className="empty-screen-tr">
                          <td colSpan={5}>
                            <EmptyScreen
                              content={noReulstContent}
                              emptyScreenImage={NoResultImage}
                            />
                          </td>
                        </tr>
                      ) : (
                        filteredAndSortedProofs.map((verifier) => {
                          return (
                            <tr>
                              {/* <td>{verifier.connectionId}</td> */}
                              <td>
                                {verifier?.connectionId ? (
                                  <>
                                    <img
                                      className="img-fluid copy-icon"
                                      src={CopyIcon}
                                      alt="copy"
                                      onClick={() =>
                                        handleCopy(verifier?.connectionId)
                                      }
                                    />
                                    <span className="connection-id">
                                      {isMobile
                                        ? formatCellData(verifier?.connectionId)
                                        : verifier?.connectionId}
                                    </span>
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>

                              <td>
                                {verifier.isVerified ? (
                                  <span className="success-badge">
                                    Verified
                                  </span>
                                ) : verifier.state === "abandoned" ? (
                                  <span className="danger-badge">
                                    Rejected
                                  </span>
                                ) : (
                                  <span className="pending-badge">Pending</span>
                                )}
                              </td>

                              <td className="text-capitalize">
                                {verifier.state}
                              </td>

                              <td> {formatDate(verifier.updatedAt)}</td>
                              <td>
                                {verifier.isVerified && verifier.id ? (
                                  <span
                                    className="info-badge"
                                    onClick={() => handleView(verifier.id)}
                                  >
                                    View
                                  </span>
                                ) : (
                                  <span
                                    className="light-badge"
                                    // onClick={() => handleView(verifier.id)}
                                    disabled
                                  >
                                    View
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IssuedCredentials;
